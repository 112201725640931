import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService, Message } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TRISTATECHECKBOX_VALUE_ACCESSOR } from 'primeng/tristatecheckbox';
import { AttachmentsDetailComponent } from 'src/app/attachments/attachments-detail/attachments-detail.component';
import { AttachmentsService } from 'src/app/attachments/attachments.service';
import { TransactionStatusService } from 'src/app/configuration/transaction-status/transaction-status.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ViewsService } from 'src/app/core/view/views.service';
import { LoginService } from 'src/app/login/login.service';
import { MailingDetailComponent } from 'src/app/mailing/mailing-detail/mailing-detail.component';
import { OrganizationsContactsDetailComponent } from 'src/app/organizations/organizations-detail/organizations-contacts-detail/organizations-contacts-detail.component';
import { OrganizationsDetailGeneralComponent } from 'src/app/organizations/organizations-detail/organizations-detail-general/organizations-detail-general.component';
import { OrganizationsDetailComponent } from 'src/app/organizations/organizations-detail/organizations-detail.component';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { OrganizationsMeetingsDetailComponent } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/organizations-meetings-detail.component';
import { TasksDetailComponent } from 'src/app/tasks/tasks-detail/tasks-detail.component';
import { TasksService } from 'src/app/tasks/tasks.service';
import { UsersService } from 'src/app/users/users.service';
import { ComponentService } from '../../core/view/component.service';
import { ViewComponentFormComponent } from '../../core/view/component/form/view-component-form.component';
import { TransactionsService } from '../transactions.service';
import { TransactionLinesDetailComponent } from './transactions-lines/transaction-lines-detail/transaction-lines-detail.component';
import { TransactionsLinesService } from './transactions-lines/transactions-lines.service';
import { CustomHistoryService } from '../../core/customHistory.service';
import { EntitiesService } from 'src/app/configuration/entities.service';
import { TranslateService } from '@ngx-translate/core';
import { AddCampaignsToEntityComponent } from 'src/app/campaigns/add-campaigns-to-entity/add-campaigns-to-entity.component';

@Component({
  selector: 'app-transactions-detail',
  templateUrl: './transactions-detail.component.html',
  styleUrls: ['./transactions-detail.component.scss']
})
export class TransactionsDetailComponent implements OnInit {
  @ViewChild("view") public view: ViewComponent;
  public viewConfig: any = {
    showCancel: false
  };
  private config: any = {};
  public transaction: any = {};
  public transaction_db: any = {};
  public transaction_type_id;
  public transactions_status: any[] = [];
  private transactionStatus: any;
  public showMessageBlockData: boolean = false;
  public blockPipeline: boolean = false;
  // public user: any;
  public disableForm = "";

  public dialogRef: DynamicDialogRef = null;
  constructor(
    private injector: Injector,
    private transactionsService: TransactionsService,
    private transactionsStatusService: TransactionStatusService,
    private entitiesService: EntitiesService,
    private transactionsLinesService: TransactionsLinesService,
    private attachmentsService: AttachmentsService,
    private tasksService: TasksService,
    private meetingsService: MeetingsService,
    private loginService: LoginService,
    private userService: UsersService,
    private viewsService: ViewsService,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dialogService: DialogService,
    private router: Router, private usersService: UsersService,
    private route: ActivatedRoute,
    private customHistoryService: CustomHistoryService,
    private translateService: TranslateService
  ) {
    this.dialogRef = this.injector.get(DynamicDialogRef, null);
    const dialogConfig = this.injector.get(DynamicDialogConfig, null);
    if (dialogConfig) {
      this.viewConfig.showCancel = true;
      this.config = dialogConfig;
    } else {
      this.route.params.subscribe(params => {
        //parses a Int:
        var contact_id = params['contact_id'] ? parseInt(params['contact_id']) : null;
        var organization_id = params['organization_id'] ? parseInt(params['organization_id']) : null;
        var from_task_id = params['from_task_id'] ? parseInt(params['from_task_id']) : null;
        this.config.data = {
          id: parseInt(params['id']),
          transaction_type_id: parseInt(params['transaction_type_id']),
          contactId: contact_id,
          organization_id: organization_id,
          from_task_id: from_task_id
        };
      });
    }
  }

  ngOnInit(): void {
    this.loadTransactionsStatus();
    if (this.config.data.id != null) {
      this.loadData(this.config.data.id);
    }
    if (this.config.data.transaction_type_id != null) this.transaction_type_id = this.config.data.transaction_type_id;
    /* Arriba se rellena this.config, no hace falta hacerlo aqui tambien, sino se llama varias veces
    this.route.params.subscribe(params => {
       if (params['id'] != null) {
         alert("this.route.params")
         this.loadData(params['id']);
       }
     })*/
  }

  loadData(id) {

    if (id != null && id != 0) {
      this.transactionsService.get(id).subscribe({
        next: (data: any) => {
          if (data != null) {
            this.transaction = data;
            this.transaction.tracking_notes = null;

            //Guardamos la transaccion que viene en la base de datos
            this.transaction_db = JSON.stringify(data);

            //Miramos si es un estado final de transaccion de tipo oportunidad para sacar la venta relacionada, si tiene (para la información de sage)
            if (this.loginService.hasFeature('31')) {
              //Por si han puesto el customizado
              if (this.transaction.transactionStatus.final) {
                //Buscamos si tiene una venta.
                this.transactionsService.getSaleRelated(this.transaction.id).subscribe({
                  next: (data: any) => {
                    this.transaction.offer = data;
                    this.view.fireEvent("transactionSAGE.custom", "reload");
                  },
                  error: (error: any) => {
                    console.log(error);
                  }
                });
              }
            }

            this.transactionsStatusService.get(this.transaction.transaction_status_id).subscribe({
              next: (data: any) => {
                this.transactionStatus = data;
                var rolesData;
                if (this.transactionStatus.metadata != null) rolesData = this.transactionStatus.metadata["rolesData"]?.map(rD => rD.id);
                //Comprobamos roles asignados al estado de la transacción para bloquearlo si no los contiene el usuario.
                if (this.transactionStatus.block_data && typeof (rolesData) !== "undefined" && !rolesData.includes(this.loginService.get("roleId")) && this.loginService.get("isSuperAdmin") == "false") {
                  this.showMessageBlockData = true;
                  this.disableForm = "pointer-events: none; opacity: 0.5;";
                } else {
                  this.showMessageBlockData = false;
                  this.disableForm = "";
                }
                if (this.transactionStatus.block_pipeline) {
                  this.blockPipeline = true;
                } else { this.blockPipeline = false; }
              },
              error: (error: any) => {
                this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
              }
            });
          } else {
            //Por si van a una borrada
            this.router.navigate(['/transactions/',
              (this.config.data.transaction_type_id == null ? 0 : this.config.data.transaction_type_id),
              (this.config.data.contact_id == null ? 0 : this.config.data.contact_id),
              (this.config.data.organization_id == null ? 0 : this.config.data.organization_id),
              (this.config.data.from_task_id == null ? 0 : this.config.data.from_task_id),
              0
            ]);
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    } else {
      this.transaction.transaction_type_id = this.config.data.transaction_type_id;
      this.transaction.organization_id = this.config.data.organization_id;
      this.transaction.date = new Date();
      this.transaction.base_amount = 0;
      this.transaction.cost_amount = 0;
      this.transaction.profit_calculated = 0;
      this.transaction.tax_amount = 0;
      this.transaction.total_amount = 0;
      this.transaction.total_amount_calculated = 0;
      var userId = parseInt(localStorage.getItem("userId"));
      this.transaction.responsible_id = userId;
      if (this.config.data.contactId) this.transaction.contact_id = this.config.data.contactId;
    }
  }

  isTransactionModified() {
    //Eliminamos las relaciones
    var transactionDB = JSON.parse(this.transaction_db);
    transactionDB.createdBy = null;
    transactionDB.created_by = null;
    transactionDB.updatedBy = null;
    transactionDB.updated_by = null;
    transactionDB.offer = null;
    transactionDB.responsible = null;
    transactionDB.responsible2 = null;
    transactionDB.contact = null;
    transactionDB.transactionType = null;
    transactionDB.transactionStatus = null;
    transactionDB.organization = null;
    transactionDB.relatedTransaction = null;
    transactionDB.business = null;

    var transactionActualStringify = JSON.stringify(this.transaction);
    var transactionActual = JSON.parse(transactionActualStringify);
    transactionActual.createdBy = null;
    transactionActual.created_by = null;
    transactionActual.updatedBy = null;
    transactionActual.updated_by = null;
    transactionActual.offer = null;
    transactionActual.responsible = null;
    transactionActual.responsible2 = null;
    transactionActual.contact = null;
    transactionActual.transactionType = null;
    transactionActual.transactionStatus = null;
    transactionActual.organization = null;
    transactionActual.relatedTransaction = null;
    transactionActual.business = null;

    return JSON.stringify(transactionActual) != JSON.stringify(transactionDB);
  }

  loadDataTotal(id) {
    this.transactionsService.get(id).subscribe({
      next: (data: any) => {
        this.transaction.cost_amount = data.cost_amount;
        this.transaction.base_amount = data.base_amount;
        this.transaction.tax_amount = data.tax_amount;
        this.transaction.total_amount = data.total_amount;
        this.transaction.total_amount_calculated = data.total_amount_calculated;
        this.transaction.profit_calculated = data.profit_calculated;
        this.view.fireEvent("transaction.edit.transactionsLines", "reload");
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });

  }

  loadTransactionsStatus() {
    this.transactionsStatusService.all({ transaction_type_id: this.config.data.transaction_type_id }).subscribe({
      next: (data: any) => {
        this.transactions_status = data.rows;
        if (this.transaction.id == null) {
          this.transaction.transaction_status_id = this.transactions_status[0].id;
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  save(event: any, save_continue: Boolean = false, callback = null) {


    if (this.transaction.block_data) {
      this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.transactions.detail.cant_modify_transaction") });
      return;
    }
    if (event.valid == false) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    if (event.valid) {
      if (typeof this.transaction.id === "undefined") {
        if (this.config.data.from_task_id) var from_task_id = this.config.data.from_task_id;
        if (from_task_id != null) this.transaction.from_task_id = from_task_id;
        this.transactionsService.add(this.transaction).subscribe({
          next: (data: any) => {
            if (!save_continue) {
              if (this.dialogRef) this.dialogRef.close(data);
              else {
                this.transaction = data;
                this.transaction.tracking_notes = null;
                //this.loadData(this.transaction.id);
                this.router.navigate(['/transactions/', this.transaction_type_id, this.transaction.id]);
              }
            } else {
              this.transaction = data;
              this.transaction.tracking_notes = null;
              this.loadData(this.transaction.id);
            }
            this.messageService.add({
              closable: false, severity: 'success', summary: this.translateService.instant("component.transaction.detail.add_transaction_correct")
            });
            this.view.fireEvent("entityHistory.custom", "reload");
            this.view.fireEvent("transactionSAGE.custom", "reload");
            this.view.fireEvent("transactions.edit.campaigns", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      } else {
        this.transactionsService.save(this.transaction.id, this.transaction).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.transaction.detail.save_transaction_correct") });
            if (!save_continue) {
              if (this.dialogRef) this.dialogRef.close(data);
            } else {
              this.transactionsStatusService.get(this.transaction.transaction_status_id).subscribe({
                next: (data: any) => {
                  this.transactionStatus = data;
                  if (this.transactionStatus.final) {
                    this.showMessageBlockData = true;
                  } else {
                    this.showMessageBlockData = false;
                  }
                  if (callback != null) callback(callback)
                },
                error: (error: any) => {
                  this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
                }
              });
            }

            this.transaction.tracking_notes = null;
            this.view.fireEvent("entityHistory.custom", "reload");
            this.view.fireEvent("transactionSAGE.custom", "reload");
            this.view.fireEvent("transactions.edit.campaigns", "reload");
            //this.refreshAll();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
          }
        });
      }

    } else {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
    }
  }

  isDuplied(event, save_continue, callback) {

    if (typeof (this.transaction.total_amount) != "string") this.transaction.total_amount = this.transaction.total_amount + "";
    this.transaction.total_amount = (this.transaction.total_amount.indexOf(',') !== -1 ? this.transaction.total_amount.replace(",", ".") : this.transaction.total_amount);


    if (!event.valid) {
      this.messageService.add({ closable: false, severity: "error", detail: this.translateService.instant("general.form_incomplete") });
      return;
    }
    this.transactionsService.isDuplied(this.transaction).subscribe({
      next: (data: any) => {
        if (data == true) {
          this.coreDialogService.notify({
            message: this.translateService.instant("component.transaction.detail.transaction_duplicate"),
            header: 'Error',
            icon: 'far exclamation-circle',
            color: 'var(--orange-500)',
            accept: () => { }
          });
        } else {
          this.save(event, save_continue, callback);
        }
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  cancel() {
    if (this.dialogRef) this.dialogRef.close();
    //Deberíamos pensar si este router navigate debería ser así o retroceder una página hacia atrás:
    else this.router.navigate(['/transactions/' + this.transaction.transaction_type_id]);
  }

  redirectRelatedTransaction() {
    var saleTitle = this.translateService.instant("sale.entity.name");
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.redirect_to_sale_generated", { sale: saleTitle.toLowerCase() }),
      icon: "pi pi-info-circle",
      accept: () => {
        //TODO:
        this.router.navigate(['/transactions', this.transaction.relatedTransaction.transaction_type_id, this.transaction.related_transaction_id],)
      }
    });
  }

  delete() {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transactions.delete_message", { entity: this.view.getEntityBaseName() }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.delete(this.transaction.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            if (this.transaction.related_transaction_id) this.redirectRelatedTransaction();
            if (this.dialogRef) {
              this.dialogRef.close();
            }
            else {
              if (this.customHistoryService.getBackHistory() != null) {
                this.router.navigateByUrl(this.customHistoryService.getBackHistory());
              } else {
                this.router.navigate(['/transactions', this.transaction.transaction_type_id]);
              }
            }
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {
        //code
      }
    })
  }

  editTransactionLine(transactionLine) {
    const ref = this.dialogService.open(TransactionLinesDetailComponent, {
      data: {
        id: transactionLine ? transactionLine.id : null,
        transaction_id: this.transaction.id,
        entityBaseName: this.view.getEntityBaseName(),
        business_id: this.transaction.organization?.business_id
      },
      header: transactionLine ?
        this.translateService.instant("component.transaction.detail.detail_entity_line", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.transaction.detail.new_entity_line", { entity_name: this.view.getEntityBaseName() }),
      showHeader: true,
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.loadDataTotal(this.transaction.id);
      this.view.fireEvent("transaction.edit.transactionsLines", "reload");
      this.view.fireEvent("transactions.edit.mails", "reload");
      this.view.fireEvent("entityHistory.custom", "reload");
    });
  }
  deleteTransactionLine(transactionLine) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.delete_transaction_line"),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsLinesService.delete(transactionLine.id).subscribe({
          next: (data: any) => {
            this.loadDataTotal(this.transaction.id);
            this.messageService.add({ severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transaction.edit.transactionsLines", "reload");
            this.view.fireEvent("entityHistory.custom", "reload");
            this.view.fireEvent("transactions.edit.campaigns", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    });
  }
  downloadFile(attachment) {
    this.attachmentsService.download(attachment.guid);
  }

  viewAttachment(attachment, hideTabs: boolean) {

    //Para que se descargue automaticamente si no es editable.
    if (attachment != null && attachment.id != null && attachment.related_attachment_id == null) {
      this.downloadFile(attachment);
    } else {

      const ref = this.dialogService.open(AttachmentsDetailComponent, {
        data: {
          id: attachment ? attachment.id : 0,
          entity_id: this.getTransactionEntityId(),
          entity_pk_id: this.transaction.id,
          organization_id: this.transaction.organization_id,
          hideTabs: hideTabs
        },
        //header: attachment ? "Detalles del adjunto" : "Nuevo adjunto",
        width: '70%',
        /*baseZIndex: 999*/
      });
      ref.onClose.subscribe((data: any) => {
        if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
        this.view.fireEvent("transaction.attachments", "reload");
      });
    }

  }

  deleteAttachment(attachment) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.delete_message_attachment", { filename: attachment.filename }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.attachmentsService.delete(attachment.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transaction.attachments", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }

  private_status_select(id) {
    this.transaction.responsible = null;
    this.transaction.responsible2 = null;
    this.transaction.contact = null;
    this.transaction.transactionStatus = null;
    this.transaction.transaction_status_id = id;
    //Si no está creado, sólamente actualizará el estado sin guardar la entidad.
    if (this.transaction.id) {
      this.transactionsService.save(this.transaction.id, this.transaction).subscribe({
        next: (data: any) => {
          this.messageService.add({ closable: false, severity: "success", detail: this.translateService.instant("component.transaction.detail.update_status_correct") });

          if (data.generated_sale_id != null) this.router.navigate(['/transactions/', 2, data.generated_sale_id]);
          // if(data.transactionStatus.generate_transaction == true && this.transaction.transaction_type_id != 2) this.messageService.add({severity:"success", detail:"Se ha generado una venta con el nombre Venta de "+data.description+"."});

          this.loadData(this.transaction.id);

          this.view.fireEvent("transactions.edit.campaigns", "reload");
          this.view.fireEvent("entityHistory.custom", "reload");
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: "error", detail: error.error.title });
        }
      });
    }
  }


  status_selected(id) {
    //Comprobar si puedo editarlo.
    if (!this.loginService.canEditModel(this.transaction, this.view?.entity?.code)) {
      this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: this.translateService.instant("component.transaction.detail.user_not_permission") });
      return;
    }
    if (this.transaction.id == null || this.transaction.id == 0) this.private_status_select(id);
    var rolesData;
    if (this.transactionStatus.metadata != null) rolesData = this.transactionStatus.metadata["rolesStatus"]?.map(rD => rD.id);
    if (this.transactionStatus.block_pipeline && !rolesData.includes(parseInt(this.loginService.get("roleId"))) && this.loginService.get("isSuperAdmin") == "false") {
      //Si está bloqueado y no se tiene el rol que te permite cambiar estado -> el pipeline no se puede cambiar el estado.
      this.messageService.add({ closable: false, severity: 'error', detail: this.translateService.instant("component.transaction.detail.not_change_state") });
    } else {
      //Buscamos si el id tiene un mensaje, si lo tiene cargamos el popup
      this.transactionsStatusService.get(id).subscribe({
        next: (data: any) => {
          let transactStatus: any;
          transactStatus = data;
          var selected_role_status = transactStatus.metadata?.rolesStatus?.find(r => r.id == this.loginService.get("roleId"));
          var skipConfirmation = selected_role_status?.skipConfirmation;
          if (transactStatus.confirmation_text != null && transactStatus.confirmation_text != "" && !skipConfirmation) {
            this.coreDialogService.confirm({
              message: transactStatus.confirmation_text,
              header: this.translateService.instant("component.transaction.detail.confirm_action"),
              icon: "pi pi-info-circle",
              accept: () => {
                this.private_status_select(id);
              },
              reject: () => {
                //code
              }
            })
          } else {
            this.private_status_select(id);
          }
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });


    }
  }
  viewTask(task) {
    if (this.dialogRef != null) this.dialogRef.close();
    this.router.navigate(['/tasks/', this.transaction.organization_id, this.transaction.id, (task?.id ? task.id : 0)]);
  }

  deleteTask(task) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.delete_task_message", { subject: task.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.delete(task.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transaction.edit.tasks", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => { }
    })
  }
  viewMeeting(meeting) {
    /*const ref = this.dialogService.open(OrganizationsMeetingsDetailComponent, {
      data: {
        id: this.transaction.organization_id,
        contactId: meeting ? meeting.contact_id : 0,
        meetingId: meeting ? meeting.id : 0,
        deactivated: true,
        transaction_id: this.transaction.id
      },
      //header: meeting ? "Detalles de la reunión" : "Nueva reunión",
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("transaction.edit.meetings", "reload");
      this.view.fireEvent("transactions.edit.mails", "reload");
    });*/

    var params = {
      id: this.transaction.organization_id ? this.transaction.organization_id : 0,
      contactId: meeting ? meeting.contact_id : 0,
      meetingId: meeting ? meeting.id : 0,
      deactivated: true,
      transaction_id: this.transaction.id
    }
    // Utiliza router.navigate y pasa el objeto de parámetros
    this.router.navigate(['/meetings/', (meeting ? meeting.id : 0)], { queryParams: params });
  }
  deleteMeeting(meeting) {
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.delete_meeting_message", { subject: meeting.subject }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.meetingsService.delete(meeting.id).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.view.fireEvent("transaction.edit.meetings", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    })
  }

  unlink(task) {
    //El task debe desvincularse del transaction.
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.unlink_task_from_transaction", { subject_task: task.subject }),
      header: "Desvincular actividad",
      icon: "pi pi-info-circle",
      accept: () => {
        this.tasksService.unlink(task.id, task).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.transaction.detail.unlink_correct") });
            this.view.fireEvent("transaction.edit.tasks", "reload");
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        });
      },
      reject: () => {

      }
    });
  }

  link(task) {
    //La transacción debe vincularse a la transacción.
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.detail.link_task_from_transaction", { subject_task: task.subject }),
      header: "Vincular transacción",
      icon: "pi pi-info-circle",
      accept: () => {
        //TODO: método link().
        this.tasksService.unlink(task.id, task).subscribe(
          data => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("component.transaction.detail.link_correct") });
            this.view.fireEvent("transaction.edit.tasks", "reload");
          },
          error => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        );
      },
      reject: () => {

      }
    });
  }
  reorderPositions(dragIndex, dropIndex, dragItem, dropItem, arrayIds, params) {
    this.transactionsLinesService.updatePositions({
      dragIndex: dragIndex,
      dropIndex: dropIndex,
      arrayIds: arrayIds,
      _sortBy: params._sortBy,
      _sortDirection: params._sortDirection,
      _page: params._page,
      _pageSize: params._pageSize
    }).subscribe(
      data => {
        this.view.fireEvent("transaction.edit.transactionsLines", "reload");
      }
    )
    return [dragItem, dropItem];
  }

  newMail(emailAcc, entity_id, entity_pk_id, template_type_code, searchRelated) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        to: emailAcc,
        entity_id: entity_id,
        entity_pk_id: entity_pk_id,
        organization_id: this.transaction.organization_id,
        contact_id: this.transaction.contact_id,
        //template_type_id: 1
        template_type_code: template_type_code,
        searchRelated: searchRelated
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("transactions.edit.mails", "reload");
    });
  }

  viewMail(data) {
    var entity_id = this.getTransactionEntityId();
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        mail_id: data.notification_email_id,
        organization_id: data.organization_id,
        entity_id: entity_id,
        template_type_code: "MAIL",
        notification_to_id: data.id,
        searchRelated: true
      },
      header: this.translateService.instant("general.new_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data: any) => {
      if (typeof (this.view) !== "undefined") this.viewsService.changeView(this.view.code);
      this.view.fireEvent("transactions.edit.mails", "reload");
    });
  };

  resendMail(data) {
    const ref = this.dialogService.open(MailingDetailComponent, {
      data: {
        resendMail: true,
        mail_id: data.notification_email_id,
        to: null,
        //template_type_id: 1,
        template_type_code: "MAIL",
        is_generic: true,
        searchRelated: true
      },
      header: this.translateService.instant("general.resend_mail_title"),
      width: '70%',
    });
    ref.onClose.subscribe((data) => {

    });
  }

  getTransactionEntityId() {
    switch (this.transaction.transaction_type_id) {
      case 1:
        return 13; //oportunity
      case 2:
        return 28; //sale
      default:

        var cacheEntities = this.entitiesService.cacheEntities;
        var id = cacheEntities.find(m => m.code == this.transaction.transactionType.code).id;
        if (id) return id;
        else return 29; //transaction
    }
  }

  calculateTotals() {
    this.transaction.total_amount_calculated = this.transaction.base_amount + this.transaction.tax_amount;
    if (this.transaction.discount > 0) this.transaction.base_amount = this.transaction.base_amount - (this.transaction.base_amount * this.transaction.discount / 100);
    this.transaction.profit_calculated = this.transaction.total_amount_calculated - this.transaction.cost_amount; //Calculamos el beneficio.
  }

  recalculate() {
    this.transaction.total_amount = this.transaction.total_amount_calculated + "";
  }

  refreshAll() {
    this.loadData(this.transaction.id);
    //this.view.fireEvent("transaction.edit.general", "reload");
    this.view.view?.configuration?.components?.array?.forEach(component => {
      this.view.fireEvent(component.code, "reload");
    });
    this.view.fireEvent("transactions.edit.campaigns", "reload");
  }

  goToCalendar() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this.router.navigate(['/calendar']);
  }

  unlinkCampaign(campaign) {

    this.transaction.campaign_id = null;
    this.coreDialogService.confirm({
      message: this.translateService.instant("component.transaction.unlink_campaign", { name: campaign.name }),
      header: this.translateService.instant("general.confirmation_delete_title"),
      icon: "pi pi-info-circle",
      accept: () => {
        this.transactionsService.save(this.transaction.id, this.transaction).subscribe({
          next: (data: any) => {
            this.messageService.add({ closable: false, severity: 'success', detail: this.translateService.instant("general.confirmation_delete") });
            this.refreshAll();
            //this.dynamicDialogRef.close();
          },
          error: (error: any) => {
            this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
          }
        })
      },
      reject: () => {

      }
    });
  }
  openCampaign(campaign) {
    window.open("/campaigns/" + (campaign.id ? campaign.id : 0));
  }

  addCampaigns() {
    const ref = this.dialogService.open(AddCampaignsToEntityComponent, {
      data: {
        id: this.transaction.id,
        entity: 'transaction',
        campaign_id: this.transaction.campaign_id
      },
      header: this.translateService.instant("component.campaigns.addCampaigns"),
      width: '70%'
    });
    ref.onClose.subscribe((data: any) => {
      this.refreshAll();
    });
  };

  onEvent(event: any) {
    if (event.event == "refreshAll" && event.action.name == "refreshAll") this.refreshAll();
    if (event.component == "transaction.edit.general") {
      if (event.event == "action" && event.action.name == "delete") this.delete();
      if (event.event == "action" && event.action.name == "cancel") this.cancel();
      if (event.event == "action" && event.action.name == "save") this.isDuplied(event, false, null);
      if (event.event == "action" && event.action.name == "save_continue") this.isDuplied(event, true, null);
      if (event.event == "fieldAction" && event.action.name == "new-mail" && event.entityField.model_property == "organization.email") {
        var entity_id = this.getTransactionEntityId();
        this.newMail(event.data.organization.email, entity_id, event.data.id, "MAIL", true);
      }
      if (event.event == "fieldAction" && event.action.name == "new-mail" && event.entityField.model_property == "contact.email") {
        let email = event.data.contact.email;
        let id = event.data.id
        var entity_id = this.getTransactionEntityId();
        //Comprobamos si lo que hay es distinto a lo que había para cuando nos cambian el contacto.
        if (this.isTransactionModified()) {
          this.coreDialogService.confirm({
            message: this.translateService.instant("component.transaction.detail.not_saved_data_send_email"),
            header: "Confirmación de guardado",
            icon: "pi pi-info-circle",
            accept: () => {
              this.isDuplied(event, true, this.newMail(email, entity_id, id, "MAIL", true));
            },
            reject: () => {
            }
          })
        } else {
          this.newMail(email, entity_id, id, "MAIL", true);
        }
      }
      if (event.event == "fieldAction" && event.action.name == "new-mail" && typeof (event.action.param) !== "undefined" && event.action.param == "INTERNAL") {
        //Son solo para los new_emails internos.
        //Cargamos los datos del usuario (o responsible_id o responsible_user_id)
        this.usersService.get(event.value).subscribe({
          next: (data: any) => {
            var entity_id = this.getTransactionEntityId();
            var toInfo = { name: data.name, email: data.email }
            this.newMail(toInfo, entity_id, event.data.id, "MAIL_INTERNAL", false);
          },
          error: (error: any) => {
            this.messageService.add({ severity: "error", detail: error.error.title });
          }
        });
      }
      if (event.event == "action" && event.action.name == "generatePDF") {
        this.viewAttachment(null, true);
      }
      if (event.event == "onChange") {
        this.calculateTotals();
      }
    } else if (event.component == "transaction.edit.general.total") {
      if (event.event == "fieldAction" && event.action.name == "recalculate") {
        this.recalculate();
      }
    } else if (event.component == "transaction.edit.transactionsLines") {
      if (event.event == "reorder") {
        let items = this.reorderPositions(event.data.dragIndex, event.data.dropIndex, event.dragItem, event.dropItem, event.ids, event.params);
      }
      if (event.event == "dblclick") {

        this.editTransactionLine(event.data);
      }
      if (event.event == "action" && event.action.name == "add") this.editTransactionLine(null);
      if (event.event == "action" && event.action.name == "edit") this.editTransactionLine(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteTransactionLine(event.data);
    } else if (event.component == "transaction.attachments") {
      if (event.event == "action" && event.action.name == "add") this.viewAttachment(null, false);
      if (event.event == "action" && event.action.name == "generatePDF") {
        this.viewAttachment(null, true);
      }
      if (event.event == "action" && event.action.name == "delete") this.deleteAttachment(event.data);
      if (event.event == "dblclick") this.viewAttachment(event.data, false);
      if (event.event == "action" && event.action.name == "download") this.downloadFile(event.data);
    } else if (event.component == "transaction.edit.tasks") {
      if (event.event == "dblclick") this.viewTask(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewTask(null);
      if (event.event == "action" && event.action.name == "edit") this.viewTask(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteTask(event.data);
      if (event.event == "action" && event.action.name == "unlink") this.unlink(event.data);
      if (event.event == "action" && event.action.name == "link") this.link(event.data);
    } else if (event.component == "transaction.edit.meetings") {
      if (event.event == "dblclick") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "add") this.viewMeeting(null);
      if (event.event == "action" && event.action.name == "edit") this.viewMeeting(event.data);
      if (event.event == "action" && event.action.name == "delete") this.deleteMeeting(event.data);
      if (event.event == "action" && event.action.name == "calendar") this.goToCalendar();
    } else if (event.component == "transactions.edit.mails") {
      if (event.event == "dblclick") this.viewMail(event.data);
      if (event.event == "action" && event.action.name == "view") this.viewMail(event.data);
      if (event.event == "action" && event.action.name == "resend-mail") this.resendMail(event.data);
    } else if (event.component == "transaction.edit.general.total") {
      if (event.event == "onChange") {
        //this.calculateTotals();
      }
    } else if (event.component == "transactions.edit.campaigns") {
      if (event.event == "action" && event.action.name == "edit") this.openCampaign(event.data);
      if (event.event == "action" && event.action.name == "add") this.addCampaigns();
      if (event.event == "action" && event.action.name == "unlink") this.unlinkCampaign(event.data);
    }
    if (event.component == "view" && event.event == "entity-loaded" && this.view != null) {
      this.config.header = typeof (this.transaction.id) !== "undefined" ? this.translateService.instant("component.transaction.detail.detail_entity", { entity_name: this.view.getEntityBaseName() })
        :
        this.translateService.instant("component.transaction.detail.new_entity", { entity_name: this.view.getEntityBaseName() });
    }
    if (this.showMessageBlockData) {
      this.view.getComponent("transaction.edit.general").locked = true;
      this.view.getComponent("transaction.edit.tasks").locked = true;
      this.view.getComponent("transaction.edit.transactionsLines").locked = true;
      this.view.getComponent("transaction.edit.meetings").locked = true;
      this.view.getComponent("transaction.edit.general").locked = true;
      if (this.view.getComponent("transaction.attachments") != null) this.view.getComponent("transaction.attachments").locked = true;
    } else {
      if (this.view.getComponent("transaction.edit.general") != null) this.view.getComponent("transaction.edit.general").locked = false;
      if (this.view.getComponent("transaction.edit.tasks") != null) this.view.getComponent("transaction.edit.tasks").locked = false;
      if (this.view.getComponent("transaction.edit.transactionsLines") != null) this.view.getComponent("transaction.edit.transactionsLines").locked = false;
      if (this.view.getComponent("transaction.edit.meetings") != null) this.view.getComponent("transaction.edit.meetings").locked = false;
      if (this.view.getComponent("transaction.edit.general") != null) this.view.getComponent("transaction.edit.general").locked = false;
      if (this.view.getComponent("transaction.attachments") != null) this.view.getComponent("transaction.attachments").locked = false;
    }
  }
}
